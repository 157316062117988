<template>
  <v-dialog
    v-model="dialog"
    scrollable
    transition="dialog-bottom-transition"
    max-width="550"
  >
    <v-form>
      <v-card>
        <v-toolbar dark color="light-green" class="elevation-0 px-3">
          <span class="headline">Gestionar grupos técnicos</span>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <!-- begin::Grade info -->
            <v-row>
              <v-col>
                <div class="black--text mb-1 flex-column">
                  <div class="text-body-2">Grado:</div>
                  <span class="text-h6 font-weight-medium">{{
                    grade.name
                  }}</span>
                </div>
              </v-col>
              <v-col>
                <div class="black--text mb-1 flex-column">
                  <div class="text-body-2">Nivel académico:</div>
                  <span class="text-h6 font-weight-medium">{{
                    grade.academic_level ? grade.academic_level.name : ""
                  }}</span>
                </div>
              </v-col>
            </v-row>
            <!-- end::Grade info -->
          </v-container>
          <v-divider class="mx-4 my-0"></v-divider>
          <v-container>
            <v-row>
              <v-col>
                <span class="text-body-1"
                  >Selecciona los grupos técnicos que conformarán cada una de
                  las especialidades</span
                >
              </v-col>
            </v-row>
            <v-alert
              v-if="!specialities.length"
              dense
              text
              type="info"
              class="mt-2"
            >
              No hay ninguna especialidad registrada, debes agregarlas en el
              apartado de especialidades.
            </v-alert>
            <v-row
              v-else
              v-for="speciality in specialities"
              :key="speciality.id"
            >
              <v-col>
                <span class="text-subtitle-1 black--text font-weight-medium">{{
                  speciality.name
                }}</span>
                <!-- begin::Technical groups -->
                <v-chip-group
                  v-model="technicalGroups[speciality.id]"
                  column
                  multiple
                  active-class="light-green--text"
                >
                  <v-chip
                    filter
                    outlined
                    v-for="group in groups"
                    :key="group.id"
                    :value="group.id"
                    @click:close="removeGroupInSpeciality(speciality.id, group)"
                  >
                    {{ `Grupo ${group.name}` }}
                  </v-chip>
                </v-chip-group>
                <!-- end::Technical groups -->
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-end pt-0">
          <v-btn text outlined class="mr-1" @click="closeModal">Cerrar</v-btn>
          <v-btn
            color="light-green"
            dark
            depressed
            :loading="isLoading"
            @click="save"
            >Guardar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import specialityRepository from "@/repositories/specialityRepository";
import groupRepository from "@/repositories/groupRepository";
import technicalGroupRepository from "@/repositories/technicalGroupRepository";

export default {
  name: "ManageTechnicalGroupsModal",
  data() {
    return {
      isLoading: false,
      dialog: false,
      specialities: [],
      groups: [],
      technicalGroups: {},
    };
  },
  props: {
    grade: {
      type: Object,
    },
  },
  methods: {
    toggleModal() {
      this.dialog = !this.dialog;
    },

    closeModal() {
      this.toggleModal();
      this.specialities = [];
      this.groups = [];
      this.technicalGroups = {};
    },

    save() {
      this.isLoading = true;
      // Given format to object that will be send
      let gradeTechnicalGroups = {
        grade_id: this.grade.id,
        technical_groups: [],
      };
      // Iterate through pair of speciality and their groups
      Object.entries(this.technicalGroups).forEach(
        ([speciality_id, groups]) => {
          if (groups.length > 0) {
            gradeTechnicalGroups.technical_groups.push({
              speciality_id,
              groups,
            });
          }
        }
      );
      technicalGroupRepository
        .saveTechnicalGroup(gradeTechnicalGroups)
        .then(({ data }) => {
          // Showing alert with response data
          this.sweetAlertResponse(data);
          // Retrieving data and hidding modal if operation has been completed
          if (data.status) {
            this.$emit("reload-data");
            this.closeModal();
          }
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible guardar los registros 🙁",
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    getSpecialities() {
      
      specialityRepository
      .getAllSpecialities()
      .then(({ data }) => {
          this.specialities = data;
        })
        .catch((err) => {
          console.error(err);
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        });
    },

    getGroups() {
      groupRepository
        .getAllGroups()
        .then(({ data }) => {
          this.groups = data;
        })
        .catch((err) => {
          console.error(err);
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        });
    },

    getTechnicalGroupsOfGrade(id) {
      technicalGroupRepository
        .getTechnicalGroupsByGrade(id)
        .then(({ data }) => {
          //Iterate trough technica groups
          data.forEach((item) => {
            // Check  if doenst exist a key with that name
            if (!this.technicalGroups[item.speciality_id]) {
              this.technicalGroups[item.speciality_id] = [];
            }
            // Saving groups in respective key
            this.technicalGroups[item.speciality_id].push(item.group_id);
          });
          this.getSpecialities();
          this.getGroups();
        })
        .catch((err) => {
          console.error(err);
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        });
    },
  },
  watch: {
    dialog(newValue, oldValue) {
      // If modal was open, make request for fill specialities and groups
      if (newValue) {
        this.getTechnicalGroupsOfGrade(this.grade.id);    
      }
    },
  },
};
</script>
