<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    scrollable
    persistent
    max-width="550"
  >
    <v-form>
      <v-card>
        <v-toolbar dark color="light-green" class="elevation-0 px-3">
          <span class="headline"
            >Gestión de docentes que imparten la asignatura</span
          >
        </v-toolbar>
        <v-card-text>
          <v-container>
            <!-- begin::Subject info -->
            <v-row v-if="Object.keys(subject).length > 0">
              <v-col>
                <div class="black--text mb-1 flex-column">
                  <div class="text-body-2">Asignatura:</div>
                  <span class="text-h6 font-weight-medium">{{
                    subject.name
                  }}</span>
                </div>
              </v-col>
              <v-col>
                <div class="black--text mb-1 flex-column">
                  <div class="text-body-2">Tipo de asignatura:</div>
                  <span class="text-h6 font-weight-medium">{{
                    `${subject.subject_evaluation_scale.subject_type}, ${subject.subject_evaluation_scale.subject_evaluation_methodology}`
                  }}</span>
                </div>
              </v-col>
              <v-col>
                <div class="black--text mb-1 flex-column">
                  <div class="text-body-2">Grado:</div>
                  <span class="text-h6 font-weight-medium">{{
                    subject.grade ? subject.grade : ""
                  }}</span>
                </div>
              </v-col>
              <v-col>
                <div class="black--text mb-1 flex-column">
                  <div class="text-body-2">Especialidad:</div>
                  <span class="text-h6 font-weight-medium">{{
                    subject.speciality ? subject.speciality : ""
                  }}</span>
                </div>
              </v-col>
            </v-row>
            <!-- end::Subject info -->
          </v-container>
          <v-divider class="mx-4 my-0"></v-divider>
          <v-container>
            <v-row>
              <v-col>
                <v-text-field
                  outlined
                  dense
                  color="light-green"
                  hide-details
                  prepend-icon=""
                  prepend-inner-icon="mdi-magnify"
                  placeholder="Buscar..."
                  v-model="search"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col>
                <span
                  class="text-body-1 text-center  "
                  v-if="subjectTeachersData.length == 0"
                  >Ningún docente agregado.</span
                >
                <v-chip
                  v-else
                  v-for="item in subjectTeachersData"
                  :key="item.id"
                  dark
                  close
                  :color="`light-green`"
                  class="mr-3 mb-3"
                  @click:close="removeTeacher(item.user_id)"
                >
                  <v-avatar
                    left
                    :color="`white`"
                    class="light-green--text"
                    size="10"
                  >
                    {{ item.teacher_code }}
                  </v-avatar>
                  {{ item.teacher_full_name }}
                </v-chip>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span class="text-h6">Docentes</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span
                  class="text-body-1 text-center  "
                  v-if="filteredTeachers.length == 0 && search != ''"
                  >No se ha encontrado ninguna coincidencia</span
                >
                <span
                  class="text-body-1 text-center  "
                  v-else-if="filteredTeachers.length == 0 && !search"
                  >No hay ningún docente registrado</span
                >
                <v-virtual-scroll
                  :items="filteredTeachers"
                  :item-height="50"
                  height="350"
                  v-else
                >
                  <template v-slot:default="{ item }">
                    <v-list-item>
                      <v-list-item-avatar>
                        <v-avatar
                          color="light-green"
                          size="56"
                          class="white--text"
                        >
                          {{ item.first_name[0] + item.last_name[0] }}
                        </v-avatar>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title>{{
                          `${item.first_name} ${item.last_name}`
                        }}</v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-btn
                          depressed
                          small
                          v-if="!hasBeenSelected(item.id_user)"
                          @click="addTeacher(item)"
                        >
                          Agregar
                          <v-icon color="light-green" right>
                            mdi-plus
                          </v-icon>
                        </v-btn>
                        <v-btn
                          depressed
                          small
                          v-else
                          @click="removeTeacher(item.id_user)"
                        >
                          Remover
                          <v-icon color="orange darken-4" right>
                            mdi-close
                          </v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                </v-virtual-scroll>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-end pt-0">
          <v-btn text outlined class="mr-1" @click="closeModal">Cerrar</v-btn>
          <v-btn
            color="light-green"
            depressed
            dark
            :loading="isLoading"
            @click="save"
            >Guardar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import subjectTeacherRepository from "@/repositories/subjectTeacherRepository";
import { FETCH_USERS } from "@/core/services/store/role.module.js";

export default {
  name: "AssignSubjectTeachersModal",

  data() {
    return {
      isLoading: false,
      dialog: false,
      subjectTeachers: [],
      subjectTeachersData: [],
      search: "",
    };
  },
  props: {
    subject: {
      type: Object,
    },
  },
  methods: {
    toggleModal() {
      this.dialog = !this.dialog;
    },

    closeModal() {
      this.toggleModal();
      this.subjectTeachers = [];
      this.subjectTeachersData = [];
      this.search = "";
    },

    save() {
      this.isLoading = true;
      const payload = {
        subject_id: this.subject.id,
        users: this.subjectTeachers,
      };
      subjectTeacherRepository
        .updateSubjectTeacher(this.subject.id, payload)
        .then(({ data }) => {
          // Showing alert with response data
          this.fireToast({
            icon: data.status ? "success" : "error",
            title: data.message,
          });
          // Retrieving data and hidding modal if operation has been completed
          if (data.status) {
            this.$emit("reload-data");
            this.closeModal();
            this.stages = [];
          }
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible guardar los registros 🙁",
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    getSubjectTeachersOfSubject(id) {
      subjectTeacherRepository
        .getSubjectTeachersBySubject(id)
        .then(({ data }) => {
          this.subjectTeachersData = data;
          // Guardando en el array de etapas el id de las que ya estan relacionados con la asignatura para mostrarla seleccionada
          data.forEach((item) => {
            this.subjectTeachers.push(item.user_id);
          });
        })
        .catch((err) => {
          console.error(err);
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        });
    },

    teacherData(id) {
      return this.teachers.find((item) => item.id_user == id);
    },

    hasBeenSelected(id) {
      return this.subjectTeachers.indexOf(id) > -1;
    },

    addTeacher({ id_user, first_name, last_name }) {
      this.subjectTeachers.push(id_user);
      this.subjectTeachersData.push({
        user_id: id_user,
        teacher_full_name: `${first_name} ${last_name}`,
        teacher_code: first_name[0] + last_name[0],
      });
    },

    removeTeacher(id) {
      this.subjectTeachers = this.subjectTeachers.filter((item) => item != id);
      this.subjectTeachersData = this.subjectTeachersData.filter(
        (item) => item.user_id != id
      );
    },
  },
  watch: {
    dialog(newValue, oldValue) {
      // If modal was open, make request for fill sections and stageTypes
      if (newValue) {
        this.$store.dispatch(FETCH_USERS);
        this.getSubjectTeachersOfSubject(this.subject.id);
      }
    },
  },
  computed: {
    teachers() {
      let userTypes = [];
      if (process.env.APP_ENV === "local") {
        userTypes.push("Superadministrador");
        userTypes.push("Superadmin");
      } else {
        userTypes.push("Docente académico");
        userTypes.push("Docente técnico");
      }
      return this.$store.getters.getUsersByUserType(userTypes);
    },

    filteredTeachers() {
      return this.teachers.filter(
        (item) =>
          item.first_name
            .toLowerCase()
            .includes(this.search.toLowerCase().trim()) ||
          item.last_name
            .toLowerCase()
            .includes(this.search.toLowerCase().trim())
      );
    },
  },
};
</script>
